

import { defineComponent } from 'vue'
import store from "@/store"
import { Card, Table, /* DataList, */ Searchbar, SelectSingleSearch, DateInput, UiTabs, UiTab, OptionButtonGroup } from '@/ui/index'
import { SessionController, EmployeeController, PresenceController, TimeEntryController, WorkbreakController } from '@/controller/'
import { User, Company, Presence, Employee, Workbreak, TimeEntry } from '@/model'
import { MultiplePresenceForm, MultipleWorkbreakForm } from '@/components'
import { DateTimeUtils } from '@/utils'

export default defineComponent({
    name: "Dashboard", 
    components: { Card, Table, /* DataList, */ Searchbar, SelectSingleSearch, DateInput, UiTabs, UiTab, OptionButtonGroup, MultiplePresenceForm, MultipleWorkbreakForm },
    data(){
        return{
            store,
            sessionUser: {} as User,
            sessionCompany: {} as Company,
            sessionEmployee: {} as Employee,
            employees: [] as Array<any>,
            employee: {} as Employee,
            presences: [] as Array<any>,
            workbreaks: [] as Array<any>,
            editPresence: {} as Presence | null,
            editPresences: [] as Presence[],
            editWorkbreaks: [] as Workbreak[],
            timeEntries: [] as Array<TimeEntry>,
            searchInput: "",
            employeeTableData: {
                thFields: [
                    { title: this.$t("label.date"), property: "day", type: "date" },
                    { title: this.$t("day.comeAt"), property: "come", type: "string", convert: "convertTimeStampToTime" },
                    { title: this.$t("day.goAt"), property: "go", type: "string", convert: "convertTimeStampToTime" },
                    { title: this.$t("day.presenceTime"), property: "presence", type: "string", convert: "convertSecondsToTime" },
                    { title: this.$t("label.presencesAmount"), property: "countPresences", type: "number" },
                    { title: this.$t("day.workbreaks"), property: "sumWorkbreaks", type: "string", convert: "convertSecondsToTime" },
                    { title: this.$t("label.workbreaksAmount"), property: "countWorkbreaks", type: "number" },
                    { title: this.$t("label.nettoWorktime"), property: "workTime", type: "string", convert: "convertSecondsToTime" }
                ],
                tbActions: [{ 
                    name: "editPresences", icon: store.getters.svgIcons.comego, disable: 'presenceId'
                },{ 
                    name: "editWorkbreaks", icon: store.getters.svgIcons.workbreak, disable: 'enableWorkbreakEdit'
                }]
            },
            employeeWorkTabelData: {
                thFields: [
                    { title: this.$t('label.date'), property: "start", type: "string", convert: "convertTimeStampToDate" },
                    { title: this.$t('label.projectNumberExternal'), property: "project.numberExternal", type: "string" },
                    { title: this.$t('label.projectNumberInternal'), property: "project.numberInternal", type: "string" },
                    { title: this.$t('label.projectNumberSerial'), property: "project.numberSerial", type: "string" },
                    { title: this.$t('label.project'), property: "project.title", type: "string" },
                    { title: this.$t('label.work'), property: "work.title", type: "string" },
                    { title: this.$t('label.worktimesum'), property: "countingTime", type: "string", convert: "convertSecondsToTime" }
                ]
            },
            gotPresences: false,
            gotTimeEntries: false,
            gotWorkbreaks: false,
            activeDay: 0,
            evaluationTo: (DateTimeUtils.getDayNoonTimestamp(new Date())*1000) / 1000,
            evaluationFrom: DateTimeUtils.getFirstDayOfMonth(new Date()).getTime() / 1000,
            employeeStatistics: [] as Array<any>,
            activeTab: 0,
            displayTimeRangeOptions: [
                {'label': this.$t('label.evaluation.timeRange.calendar'), 'value': 0},
                {'label': this.$t('label.evaluation.timeRange.currentWeek'), 'value': 1},
                {'label': this.$t('label.evaluation.timeRange.lastWeek'), 'value': 2},
                {'label': this.$t('label.evaluation.timeRange.currentMonth'), 'value': 3},
                {'label': this.$t('label.evaluation.timeRange.lastMonth'), 'value': 4},
                {'label': this.$t('label.evaluation.timeRange.currentQuarter'), 'value': 5},
                {'label': this.$t('label.evaluation.timeRange.lastQuarter'), 'value': 6},
                {'label': this.$t('label.evaluation.timeRange.currentHalfYear'), 'value': 7},
                {'label': this.$t('label.evaluation.timeRange.lastHalfYear'), 'value': 8},
                {'label': this.$t('label.evaluation.timeRange.currentYear'), 'value': 9},
                {'label': this.$t('label.evaluation.timeRange.lastYear'), 'value': 10},
            ],
            displayTimeRange: 0
        }
    },
    computed: {
        dateRange() {
            const from = this.evaluationFrom as number
            const to = this.evaluationTo as number
            return DateTimeUtils.getDatesFromTo(new Date(from * 1000), new Date(to * 1000))
        },
        selectableEmployees() {
            const entries = [] as any[]
            this.employees.forEach((e: Employee) => entries.push({
                uid: e.uid,
                fullname: e?.firstName + ' ' + e?.lastName
            }))
            return entries
        }, 
        selectedEmployee() {
            const employee = this.employee as Employee
            return employee.uid ? { uid: employee.uid, fullname: employee.firstName + ' ' + employee.lastName } : { uid: 0, fullname: '' }
        },
        filteredEmployees() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.employees.forEach((employee : any) => {
                    JSON.stringify(employee.firstName).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(employee);
                    !filtered.includes(employee) && JSON.stringify(employee.lastName).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(employee);
                })
            } else {
                filtered = this.employees;
            }
            return filtered;
        },
        filteredEmployeeTimeEntries() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.employee.getTimeEntries().forEach((timeEntry : TimeEntry) => {
                    (
                        (timeEntry?.project?.title && (JSON.stringify(timeEntry.project.title).toLowerCase().includes(this.searchInput.toLowerCase()))) ||
                        (timeEntry?.project?.numberExternal && (JSON.stringify(timeEntry.project.numberExternal).toLowerCase().includes(this.searchInput.toLowerCase()))) ||
                        (timeEntry?.project?.numberInternal && (JSON.stringify(timeEntry.project.numberInternal).toLowerCase().includes(this.searchInput.toLowerCase()))) ||
                        (timeEntry?.project?.numberSerial && (JSON.stringify(timeEntry.project.numberSerial).toLowerCase().includes(this.searchInput.toLowerCase()))) ||
                        (timeEntry.work && JSON.stringify(timeEntry.work.title).toLowerCase().includes(this.searchInput.toLowerCase())) ||
                        (timeEntry.customer && JSON.stringify(timeEntry.customer.custName).toLowerCase().includes(this.searchInput.toLowerCase()))
                    ) && filtered.push(timeEntry)
                })
            } else {
                filtered = this.employee.timeEntries;
            }
            return filtered;
        },
        workTimeSum() {
            let sum = 0;
            if (this.filteredEmployeeTimeEntries) {
                this.filteredEmployeeTimeEntries.forEach((timeEntry : TimeEntry) =>  sum += timeEntry.timeGone)
            }
            return sum;
        }
    },
    created() {
        this.getSessionUser()
        this.getSessionCompany()
        this.getEmployees()
        this.activeDay = DateTimeUtils.getDayNoonTimestamp(new Date())
    },
    methods:{
            //data
        getSessionUser() {
            this.sessionUser = SessionController.sessionUser
            this.sessionEmployee = SessionController.sessionEmployee
        },
        getSessionCompany() {
            this.sessionCompany = SessionController.sessionCompany;
        },
        async getEmployeeData() {
            //this.getEmployees()
            await this.getPresences()
            await this.getWorkbreaks()
            /* this.getWorkbreaks() */
            this.setEmployeeStatistics()
            await this.getTimeEntries()
        },
        async getEmployees() {
            const res = await EmployeeController.fetchEmployees();
            if(!res.error){
                this.employees = res.items;
                this.employee = this.employees[0]
                this.getEmployeeData()
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getPresences() {
            const res = await PresenceController.getPresences(this.evaluationFrom, this.evaluationTo, this.employee.uid);
            if(!res.error){
                this.presences = res.items
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" })
            }            
        },
        async getWorkbreaks() {
            const res = await WorkbreakController.getWorkbreaks(this.evaluationFrom, this.evaluationTo, this.employee.uid);
            if(!res.error){
                this.workbreaks = res.items
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" })
            }            
        },
        async getTimeEntries() {
            const timeentries = await TimeEntryController.fetchEmployeeEntries(this.employee.uid, this.evaluationFrom, this.evaluationTo)
            if (timeentries?.items) {
                this.employee.setTimeEntries(timeentries.items)
                console.log('this.employee.timeentries', this.employee.getTimeEntries())
                this.employee.setRuntimeValues()
            }
        },

            //handlers
        onEmployeeChange(item: any, value:number, text:string) {
            if (this.employee?.uid != value) {
                //console.log('handleEmployeeChange', item, value, text)
                this.employee = this.employees.find((e: Employee) => value == e.uid)
                if (this.employee?.uid) this.getEmployeeData()
            }
            //
        },
        onEvaluationDateChange(value: string, type: string) {
            if (value) {
                //const d = (DateTimeUtils.getMidnight(new Date(value)).getTime() - 86400000) / 1000
                const d = (DateTimeUtils.getDayNoonTimestamp(new Date(value))*1000) / 1000
                if (type == 'from') this.evaluationFrom = d
                else if (type == 'to') this.evaluationTo = d
                if (this.employee?.uid) this.getEmployeeData()
            }
        },
        onDisplayTimeRangeChange(value: number) {
            console.log('onDisplayTimeRangeChange', value)
            const d = new Date() //(DateTimeUtils.getMidnight(new Date()).getTime() - 86400) / 1000
            switch (value) {
                case 1:
                    this.evaluationFrom = (DateTimeUtils.getFirstDayOfWeek(d).getTime()) / 1000
                    this.evaluationTo = (DateTimeUtils.getLastDayOfWeek(d).getTime()) / 1000
                    break;
                case 2:
                    this.evaluationFrom = (DateTimeUtils.getFirstDayOfWeek(d).getTime() - 604800000) / 1000
                    this.evaluationTo = (DateTimeUtils.getFirstDayOfWeek(d).getTime() - 86400000) / 1000
                    break;
                case 3: {
                    this.evaluationFrom = (DateTimeUtils.getFirstDayOfMonth(d).getTime()) / 1000
                    this.evaluationTo = (DateTimeUtils.getLastDayOfMonth(d).getTime()) / 1000
                    break;
                }
                case 4: {
                    this.evaluationFrom = (DateTimeUtils.getFirstDayOfLastMonth(d).getTime()) / 1000
                    this.evaluationTo = (DateTimeUtils.getLastDayOfLastMonth(d).getTime()) / 1000
                    break;
                }
                case 5: {
                    this.evaluationFrom = (DateTimeUtils.getFirstDayOfQuarter(d).getTime()) / 1000
                    this.evaluationTo = (DateTimeUtils.getLastDayOfQuarter(d).getTime()) / 1000
                    break;
                }
                case 6: {
                    this.evaluationFrom = (DateTimeUtils.getFirstDayOfLastQuarter(d).getTime()) / 1000
                    this.evaluationTo = (DateTimeUtils.getLastDayOfQuarter(DateTimeUtils.getFirstDayOfLastQuarter(d)).getTime()) / 1000
                    break;
                }   
                case 7: {
                    this.evaluationFrom = (DateTimeUtils.getFirstDayOfHalfYear(d).getTime()) / 1000
                    this.evaluationTo = (DateTimeUtils.getLastDayOfHalfYear(d).getTime()) / 1000
                    break;
                }
                case 8: {
                    this.evaluationFrom = (DateTimeUtils.getFirstDayOfLastHalfYear(d).getTime()) / 1000
                    this.evaluationTo = (DateTimeUtils.getLastDayOfHalfYear(DateTimeUtils.getFirstDayOfLastHalfYear(d)).getTime()) / 1000
                    break;
                } 
                case 9: {
                    this.evaluationFrom = (new Date(d.getFullYear(), 0, 1).getTime()) / 1000
                    this.evaluationTo = (new Date(d.getFullYear(), 11, 31).getTime()) / 1000
                    break;
                }   
                case 10: {
                    this.evaluationFrom = (new Date(d.getFullYear()-1, 0, 1).getTime()) / 1000
                    this.evaluationTo = (new Date(d.getFullYear()-1, 11, 31).getTime()) / 1000
                    break;
                }                                                 
            }
            if (this.employee?.uid) this.getEmployeeData()
            this.displayTimeRange = value
        },
        onTableActionClicked(action : string, item:any) {
            console.log("handleTable", action, item)
            switch(action) {
                case "openDetail":
                    this.$router.push('/administration/dashboard/employee/' + item.uid);
                    break
                case "confirmDelete":
                    //this.confirmDeleteEmployee(item);
                    break
                case "editPresences":
                    this.editPresences = this.presences.filter((p: Presence) => item.presences.includes(p.uid))
                    break
                case "editWorkbreaks":
                    this.editWorkbreaks = this.workbreaks.filter((wb: Workbreak) => item.workbreaks.includes(wb.uid))
                    break
            }
        },     
        onPresenceEditFormUpdate(presence: Presence) {
            const idx = this.presences.findIndex((p: Presence) => p.uid == presence.uid)
            if (idx != -1) {
                this.presences.splice(idx, 1, presence)
            }
            this.editPresence = null
        },
        onPresenceEditFormDelete(presence: Presence) {
            const idx = this.presences.findIndex((p: Presence) => p.uid == presence.uid)
            if (idx != -1) {
                this.presences.splice(idx, 1)
            }
            //this.editPresence = null
        },        
        onPresenceEditFormCancel() {
            //this.presenceFormVisible = false
            this.editPresence = null
        },
        onPresenceEditFormError(error: any) {
            console.log('onPresenceEditFormError: ', error)
        },
        onPresenceEditFormClose(changed: boolean) {
            if (changed) this.setEmployeeStatistics()
            this.editPresences = []
        },        
        onWorkbreakEditFormClose(changed: boolean) {
            if (changed) this.setEmployeeStatistics()
            this.editWorkbreaks = []
        },
        onWorkbreakEditFormUpdate(workbreak: Workbreak) {
            const idx = this.workbreaks.findIndex((wb: Workbreak) => wb.uid == workbreak.uid)
            if (idx != -1) {
                this.workbreaks.splice(idx, 1, workbreak)
            }            
        },
        onWorkbreakEditFormDelete(workbreak: Workbreak) {
            console.log('onWorkDelete', workbreak)
            const idx = this.workbreaks.findIndex((wb: Workbreak) => wb.uid == workbreak.uid)
            console.log('onWorkDelete', workbreak, idx)
            if (idx != -1) {
                this.workbreaks.splice(idx, 1)
            }
            //this.editPresence = null
        },         
            //function
        formatTsDate(d: number) {
            return DateTimeUtils.convertTimeStampToDate(d, false)
        },
        formatDateInputValue(d: number) {
            return DateTimeUtils.formatDateForInput(new Date(d*1000))
        },
        convertSecondsToHours(seconds : number){
            return DateTimeUtils.convertSecondsToTime(seconds);
        },
        convertTimeStampToDate(tstamp:any) {
            return DateTimeUtils.convertTimeStampToDate(tstamp,false);
        },            
        setEmployeeStatistics() {
            this.employeeStatistics = [] as any[]

            this.dateRange.forEach((day: any) => {
                const presences = this.presences.filter((p: Presence) => (p.come >= day.from / 1000 && p.come <= day.to / 1000) || (p.go >= day.from / 1000 && p.go <= day.to / 1000))
                let wbSum = 0
                const wbEntries = [] as number[]
                this.workbreaks.forEach((wb: Workbreak) => {
                    if ((wb.start >= day.from / 1000 && wb.start <= day.to / 1000) || (wb.stop >= day.from / 1000 && wb.stop <= day.to / 1000)) {
                        wbEntries.push(wb.uid)
                        //const workbreakStop = (wb.stop && wb.stop > wb.start) ? wb.stop : (wb.stop < 86400 ? +DateTimeUtils.getMidnight(new Date(wb.start)) + wb.stop : 0)
                        //const d = new Date(wb.start)
                        //console.log('workbreakStop', workbreakStop, d, wb.start)
                        wbSum += (wb.stop ? wb.stop : +new Date()/1000) - wb.start
                        return wb
                    }
                })
                const pEntries = [] as number[]
                presences.forEach((p: Presence) => pEntries.push(p.uid))
                const entry = {
                    day: day.date,
                    come: presences.length ? presences[0].come : 0,
                    go: presences.length ? presences[0].go : 0,
                    presence: presences.length ? ((presences[0].go ? presences[0].go : +new Date()/1000) - presences[0].come) : 0,
                    presences: pEntries,
                    countPresences: pEntries.length,
                    presenceId: presences.length ? presences[0].uid : 0, 
                    sumWorkbreaks: wbSum,
                    workbreaks: wbEntries,
                    countWorkbreaks: wbEntries.length,
                    workTime: (presences.length ? ((presences[0].go ? presences[0].go : +new Date()/1000) - presences[0].come) : 0) - wbSum,
                    enableWorkbreakEdit: wbSum != 0
                }
                if (entry.workTime < 0) entry.workTime = 0
                this.employeeStatistics.push(entry)
            })
        },       
        /* checkDataLoaded() {
            if (!this.gotTimeEntries && this.employees.length) {
                this.employees.forEach((e: Employee) => {
                    this.getTimeEntries(e)
                })
                this.gotTimeEntries = true;
            }
            console.log('checkDataLoaded', this.employees.length, this.presences.length, this.workbreaks.length)
            if (this.employees.length && this.presences.length) {
                this.presences.forEach(p => {
                    const employee = this.employees.find(e => e.uid == p.employee);
                    if (employee) employee.setPresence(p)
                })
            }
            if (this.employees.length && this.workbreaks.length) {
                this.workbreaks.forEach((wBreak: Workbreak) => {
                    console.log('workbreak', wBreak)
                    const employee = this.employees.find((e: Employee) => e.uid == wBreak.employee);
                    if (employee) employee.workbreaks.push(wBreak)
                })
                this.employees.forEach((employee: Employee) => employee.setRuntimeValues())
            } 
            //}
        } */
    }
})
